<template>
  <div class="forgetThePasswordPage">
    <HomePageTop ref="head"></HomePageTop>
    <div class="forgetThePasswordPageContainer">
      <!--      头部-->
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("找回密码") }}</div>
      </div>
      <!--      验证方式选择-->
      <div class="firstStepContainer" v-if="step===0">
        <div class="verificationModeContainer" style="margin-bottom: 20px">
          <div class="flexAndCenter"><img src="../../assets/newImg/forgetThePassword/iphone.png" alt="">
            <div class="fontSize18 fontWeightBold">{{ $fanyi("手机号验证") }}</div>
          </div>
          <div class="selectBtn" @click="updateVerificationMode('mobile')">{{ $fanyi("选择") }}</div>
        </div>
        <div class="verificationModeContainer">
          <div class="flexAndCenter"><img src="../../assets/newImg/forgetThePassword/email.png" alt="">
            <div class="fontSize18 fontWeightBold">{{ $fanyi("邮箱验证") }}</div>
          </div>
          <div class="selectBtn" @click="updateVerificationMode('email')">{{ $fanyi("选择") }}</div>
        </div>
      </div>
      <!--      验证方式选择后-->
      <div class="theSecondStepContainer" v-if="step>0">
        <!-- 步骤显示 -->
        <div class="stepGroup">
          <div class="step">
            <div class="item" :class="{ isStep: step == 1}" style="margin-right: 5px">
              <div class="flexAndCenter"><span> 1 </span>
                <div>{{ $fanyi("安全验证") }}</div>
              </div>
              <div class="newIsStepBorder" :class="{ isStepBorder: step == 1 }"></div>
            </div>
            <div class="item" :class="{ isStep: step == 2}" style="margin-right: 5px">
              <div class="flexAndCenter"><span>2</span>
                <div>{{ $fanyi("设置新密码") }}</div>
              </div>
              <div class="newIsStepBorder" :class="{ isStepBorder: step == 2 }"></div>
            </div>
            <div class="item" :class="{ isStep: step == 3}">
              <div class="flexAndCenter"><span>3</span>
                <div>{{ $fanyi("找回成功") }}</div>
              </div>
              <div class="newIsStepBorder" :class="{ isStepBorder: step == 3 }"></div>
            </div>
          </div>
        </div>
        <!--        邮箱验证-->
        <div v-if="type==='email'&&step===1" class="verificationEmailContainer">
          <div class="flexAndCenter accountNumberContainer">
            <div class="flex">
              <div class="colorB4272B">*&nbsp;</div>
              <div>{{ $fanyi("请输入您的邮箱") }}&nbsp;:</div>
            </div>
            <el-input v-model="verificationAccountNumberVal" @blur="emailAccountNumberInput" style="width: 320px;"
                      :placeholder="$fanyi('请输入您注册时使用的邮箱')"/>
          </div>
          <div class="verificationAccountNumberErrorContainer" style="margin-bottom:33px">
            {{ verificationAccountNumberError }}
          </div>
          <div class="flexAndCenter codeContainer">
            <div class="flex">
              <div class="colorB4272B">*&nbsp;</div>
              <div>{{ $fanyi("验证码") }}&nbsp;:</div>
            </div>
            <el-input v-model="code" @blur="verificationAccountNumberCode" style="width: 210px;"/>
            <div v-show="!show" class="codeBtn">{{ Countdown }}s</div>
            <div v-show="show" class="codeBtn" @click="getCode">{{ $fanyi("获取验证码") }}</div>
          </div>
          <div class="verificationAccountNumberErrorContainer" style="margin-bottom:83px">{{ codeError }}</div>
          <div class="accountNumberVerificationPassContainer" @click="accountNumberVerificationNextStep">
            {{ $fanyi("下一步") }}
          </div>
        </div>
        <!--        手机号验证-->
        <div v-if="type==='mobile'&&step===1" class="verificationMobileContainer">
          <div class="flexAndCenter accountNumberContainer">
            <div class="flex">
              <div class="colorB4272B">*&nbsp;</div>
              <div>{{ $fanyi("请输入手机号") }}&nbsp;:</div>
            </div>
            <el-input v-model="verificationAccountNumberVal" @blur="mobileAccountNumberInput" style="width: 320px;"
                      :placeholder="$fanyi('请输入您注册时使用的手机号')"/>
          </div>
          <div class="verificationAccountNumberErrorContainer" style="margin-bottom:33px">
            {{ verificationAccountNumberError }}
          </div>
          <div class="flexAndCenter codeContainer">
            <div class="flex">
              <div class="colorB4272B">*&nbsp;</div>
              <div>{{ $fanyi("验证码") }}&nbsp;:</div>
            </div>
            <el-input v-model="code" @blur="verificationAccountNumberCode" style="width: 210px;"/>
            <div v-show="!show" class="codeBtn">{{ Countdown }}s</div>
            <div v-show="show" class="codeBtn" @click="getCode">{{ $fanyi("获取验证码") }}</div>
          </div>
          <div class="verificationAccountNumberErrorContainer" style="margin-bottom:83px">{{ codeError }}</div>
          <div class="accountNumberVerificationPassContainer" @click="accountNumberVerificationNextStep">
            {{ $fanyi("下一步") }}
          </div>
        </div>
        <!--        设置新密码-->
        <div v-if="step===2" class="updatePasswordContainer">
          <div class="flexAndCenter passwordContainer">
            <div class="flex">
              <div class="colorB4272B">*&nbsp;</div>
              <div>{{ $fanyi("请输入新密码") }}&nbsp;:</div>
            </div>
            <el-input v-model="password" type="password" @input="verificationPassword" style="width: 320px;"/>
          </div>
          <div class="verificationAccountNumberErrorContainer" style="margin-bottom:33px">
            {{ verificationAccountNumberError }}
          </div>
          <div class="flexAndCenter confirmPasswordContainer">
            <div class="flex">
              <div class="colorB4272B">*&nbsp;</div>
              <div>{{ $fanyi("请再次输入新密码确认") }}&nbsp;:</div>
            </div>
            <el-input v-model="confirmPassword" type="password" @input="verificationConfirmPassword"
                      style="width: 320px;"/>
          </div>
          <div class="verificationAccountNumberErrorContainer" style="margin-bottom:83px">
            {{ codeError }}
          </div>
          <div class="accountNumberVerificationPassContainer" @click="confirmPasswordUpdateNextStep">{{
              $fanyi("下一步")
            }}
          </div>
        </div>
        <!--        修改密码成功-->
        <div v-if="step===3" class="passwordUpdateSuccessContainer">
          <img class="successImg" src="../../assets/icon-1.png"/>
          <div class="hintText">{{ $fanyi("密码找回成功") }}</div>
          <div class="flexAndCenter btnList">
            <div @click="$fun.routerToPage('/')">{{ $fanyi("返回首页") }}</div>
            <div @click="$fun.routerToPage('/login')">{{ $fanyi("立即登录") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  name: "forgetThePassword",
  components: {
    HomePageTop
  },
  data() {
    return {
      step: 0,
      type: '',
      verificationAccountNumberVal: '',
      verificationAccountNumberError: '',
      accountNumberStatus: false,
      codeStatus: false,
      code: '',
      codeError: '',
      password: '',
      passwordStatus: false,
      confirmPassword: '',
      confirmPasswordStatus: false,
      show: true,
      Countdown: 60,
    }
  },
  methods: {
    //更新验证方式
    updateVerificationMode(val) {
      this.type = val;
      this.step++;
    },
    //邮箱账号格式校验
    emailAccountNumberInput(e) {
      if (/^.+@.+$/i.test(this.verificationAccountNumberVal) === false) {
        this.verificationAccountNumberError = this.$fanyi("请输入正确的邮箱地址");
        return
      }
      this.verificationAccountNumberError = '';
      this.fieldCalibration();
    },
    //手机号格式校验
    mobileAccountNumberInput() {
      if (this.verificationAccountNumberVal === '') {
        this.verificationAccountNumberError = this.$fanyi("请输入手机号");
        return
      }
      this.verificationAccountNumberError = '';
      this.fieldCalibration();
    },
    //校验验证码
    verificationAccountNumberCode() {
      this.$api.checkForgetPasswordCode({
        number: this.verificationAccountNumberVal,
        code: this.code,
      }).then((res) => {
        if (res.code != 0) {
          this.codeStatus = false;
          this.codeError = this.$fanyi("验证码错误");
          return
        }
        this.codeError = '';
        this.codeStatus = true;
      });
    },
    //账号是否注册过检验
    fieldCalibration() {
      this.$api.checkRegisterField({
        field: this.type,
        value: this.verificationAccountNumberVal,
      }).then((res) => {
        if (res.success != false) {
          this.accountNumberStatus = false;
          this.verificationAccountNumberError = this.$fanyi("账号未注册");
          return
        }
        this.verificationAccountNumberError = '';
        this.accountNumberStatus = true;
        if (this.code !== '') {
          this.verificationAccountNumberCode();
        }
      });
    },
    //获取验证码
    getCode() {
      if (this.accountNumberStatus === true && this.Countdown === 60) {
        this.show = false;
        this.Countdown = 60;
        this.show = true;
        // 60秒倒计时
        this.show = false;
        var timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.show = true;
            this.Countdown = 60;
            clearInterval(timer);
          }
        }, 1000);
        this.$api.getForgetPasswordVerificationCode({
          number: this.verificationAccountNumberVal
        }).then((res) => {
          if (res.code != 0) {
            this.$message.error(res.msg)
            this.show = true;
            this.Countdown = 60;
            clearInterval(this.timer);
          }
        });
      }
    },
    //校验账号和验证码正确后进入修改密码页面
    accountNumberVerificationNextStep() {
      if (this.accountNumberStatus === true && this.codeStatus === true) {
        this.step++
      }
    },
    //校验密码格式
    verificationPassword(e) {
      e = e.trim();
      const reg = /[\u4e00-\u9fa5]/g
      this.password = e.replace(reg, '')
      if (e.length < 6 || e.length > 16) {
        this.passwordStatus = false;
        this.verificationAccountNumberError = this.$fanyi("密码为6-16位，可由字母数字及@符号组成，不支持空格");
        return
      }
      this.verificationAccountNumberError = '';
      this.passwordStatus = true;
      this.$forceUpdate();
    },
    //校验确认密码
    verificationConfirmPassword(e) {
      e = e.trim();
      const reg = /[\u4e00-\u9fa5]/g
      this.confirmPassword = e.replace(reg, '')
      if (this.confirmPassword !== this.password) {
        this.confirmPasswordStatus = false;
        this.codeError = this.$fanyi("两次输入密码不一致");
        return
      }
      this.codeError = '';
      this.confirmPasswordStatus = true;
      this.$forceUpdate();
    },
    //确认密码修改
    confirmPasswordUpdateNextStep() {
      if (this.passwordStatus === true && this.confirmPasswordStatus === true) {
        this.$api.resetForgetPassword({
          number: this.verificationAccountNumberVal,
          code: this.code,
          new_password: this.password
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi("操作失败"));
          this.step++;
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.forgetThePasswordPage {
  .forgetThePasswordPageContainer {
    margin: 26px auto 0;
    width: 1400px;

    .EmployeesAccountTitle {
      background: #FFFFFF;
      height: 73px;
      padding: 0 26px;
      border-bottom: 1px solid #DFDFDF;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .firstStepContainer {
      height: 700px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 182px;

      .verificationModeContainer {
        width: 420px;
        height: 58px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .verificationModeContainer:first-child {
        padding: 0 10px 0 25px;

        img {
          width: 18px;
          height: 28px;
          margin-right: 13px;
        }
      }

      .verificationModeContainer:last-child {
        padding: 0 10px 0 20px;

        img {
          width: 27px;
          height: 20px;
          margin-right: 9px;
        }
      }

      .selectBtn {
        width: 80px;
        height: 38px;
        background: #B4272B;
        border-radius: 3px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
      }
    }

    .theSecondStepContainer {
      height: 700px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      flex-direction: column;

      border: 1px solid #DFDFDF;

      .stepGroup {
        width: 1400px;
        padding: 34px 0 30px;
        margin: 0 auto;
        background-color: #fff;

        .step {
          width: 1000px;
          display: flex;
          justify-content: center;
          margin: 0 auto;

          .item {
            color: #999;
            text-align: center;

            span {
              display: inline-block;
              width: 25px;
              height: 25px;
              background-color: #DFDFDF;
              line-height: 25px;
              color: white;
              border-radius: 50%;
              margin-right: 5px;
            }
          }

          //<div class="newIsStepBorder" :class="{ isStepBorder: step == 1 }"></div>
          .newIsStepBorder {
            width: 330px;
            height: 6px;
            background: #DFDFDF;
            border-radius: 3px;
            margin-top: 6px;
          }

          .isStepBorder {
            width: 330px;
            height: 6px;
            border-radius: 3px;
            margin-top: 6px;
            background: #B4272B;
          }

          .isStep {
            color: #B4272B;

            span {
              background-color: #B4272B;
              color: #fff;
            }
          }

          .successStep {
            span {
              background-color: #47bd9b;
              outline-color: #c0f1e3;
              color: #fff;
            }
          }

          .border {
            flex: 1;
            height: 1px;
            background-color: #d7d7d7;
            margin: 14px 28px 0;
          }
        }
      }

      .verificationEmailContainer, .verificationMobileContainer, .updatePasswordContainer {
        width: 1000px;
        height: 507px;
        border: 1px solid #DFDFDF;
        padding: 85px 0 0 190px;

        .accountNumberContainer {
          .flex {
            margin-right: 21px;
          }
        }

        .verificationAccountNumberErrorContainer {
          padding: 4px 0 0 165px;
          color: #B4272B;
          font-size: 12px;
          height: 12px;
        }

        .codeContainer {
          padding-left: 64px;

          .flex {
            margin-right: 21px;
          }

          .codeBtn {
            width: 100px;
            height: 38px;
            background: #B4272B;
            border-radius: 3px;
            line-height: 38px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            margin-left: 10px;
          }
        }

        .accountNumberVerificationPassContainer {
          margin-left: 154px;
          width: 320px;
          height: 38px;
          background: #B4272B;
          border-radius: 3px;
          cursor: pointer;
          line-height: 38px;
          text-align: center;
          color: #FFFFFF;
          font-size: 14px;
        }
      }

      .verificationMobileContainer {
        padding: 85px 0 0 174px;

        .codeContainer {
          padding-left: 80px;
        }

        .accountNumberVerificationPassContainer {
          margin-left: 171px;
        }

        .verificationAccountNumberErrorContainer {
          padding: 4px 0 0 180px;
        }
      }

      .updatePasswordContainer {
        padding: 85px 0 0 142px;

        .passwordContainer {
          margin-left: 64px;

          .flex {
            margin-right: 21px;
          }
        }

        .confirmPasswordContainer {
          .flex {
            margin-right: 21px;
          }
        }

        .accountNumberVerificationPassContainer {
          margin-left: 203px;
        }

        .verificationAccountNumberErrorContainer {
          padding: 4px 0 0 213px;
        }
      }

      .passwordUpdateSuccessContainer {
        width: 1000px;
        height: 507px;
        border: 1px solid #DFDFDF;
        padding-top: 60px;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          width: 80px;
          height: 80px;
        }

        .hintText {
          margin: 16px 0 81px;
        }

        .btnList {
          div {
            width: 150px;
            height: 38px;
            background: #FFFFFF;
            border: 1px solid #B4272B;
            border-radius: 3px;
            cursor: pointer;
            text-align: center;
            line-height: 38px;
            font-size: 14px;
            color: #B4272B;
          }

          div:last-child {
            background: #B4272B;
            color: #fff;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
